@keyframes scale {
  0% {
    filter: blur(3px);
  }
  100% {
    filter: blur(0);
  }
}
* {
  font-family: "Karla", sans-serif;
  box-sizing: border-box;
}
html,
body,
#root {
  width: 100%;
  height: 100%;
  font-size: 85%;
}
@media screen and (min-width: 576px) {
  html,
  body,
  #root {
    font-size: 90% !important;
  }
}
@media screen and (min-width: 768px) {
  html,
  body,
  #root {
    font-size: 95% !important;
  }
}
@media screen and (min-width: 992px) {
  html,
  body,
  #root {
    font-size: 100% !important;
  }
}
body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}
img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  user-select: none;
}
.active-overlay {
  overflow-y: hidden;
}
.container {
  max-width: 100%;
  width: 1460px;
  margin: 0 auto;
  padding: 0 25px;
}
@media screen and (min-width: 767px) {
  .container {
    padding: 0 30px;
  }
}
.loader {
  max-width: 100%;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  overflow: hidden;
  position: fixed;
  inset: 0;
  z-index: 1000;
  background-color: #063a57;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: transform 0.5s ease-out;
}
.loader img {
  max-width: 50%;
}
.loader svg {
  margin: 0 !important;
}
.del-btn {
  color: red;
  cursor: pointer;
}
.input-wrapper {
  display: flex;
  flex-direction: column;
}
.upload-input {
  display: flex;
}
.upload-input input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: -5px;
  border-right: 0;
}
.upload-input button {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.ant-modal-mask {
  z-index: 1010 !important;
}
.ant-modal-wrap {
  z-index: 1011 !important;
}
.section {
  margin-top: 9.375rem;
  color: #2a2a2a;
}
.section-heading {
  margin-bottom: 30px;
  line-height: 45px;
}
.section-heading h2 {
  font-size: 2.3125rem;
  font-weight: 700;
  font-family: "Karma", serif;
  letter-spacing: 0.03125rem;
  color: #295f95;
}
.section-heading h4 {
  margin-top: -1px;
  font-family: "Karma", serif;
  color: #888;
  letter-spacing: 0.01875rem;
  font-size: 1.1875rem;
  line-height: 1.5625rem;
}
.section-heading p {
  font-size: 1.125rem;
  line-height: 1.5625rem;
  margin-top: 10px;
}
.gallery-modal {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  padding: 0 10vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100000;
}
.gallery-modal.opened {
  display: block;
}
.gallery-modal.closed {
  display: none;
}
.gallery-modal div {
  height: 100%;
}
.gallery-modal-carousel {
  height: 100% !important;
}
.gallery-modal-item {
  height: 100% !important;
  width: 100%;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.gallery-modal-item-name {
  color: white;
}
.header {
  background: #063a57;
  max-width: 100%;
}
.header-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.header-logo img {
  max-width: 200px;
}
.header-nav {
  display: none;
  list-style: none;
  gap: 40px;
  flex: 0 0 25%;
  padding: 50px 0;
}
.header-nav a {
  text-decoration: none;
  color: #fff;
}
.header-nav li {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.5px;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
}
.header-nav li::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -6px;
  left: 0;
  background-color: #f8b46d;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
.header-nav li:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.header-nav-r {
  justify-content: flex-end;
}
.header-nav-m {
  width: 100%;
  visibility: hidden;
  height: 0;
  overflow: hidden;
}
.header-nav-m-w {
  position: relative;
  height: 0;
}
.header-nav-m .ant-select-selector {
  padding: 0 !important;
  height: 0 !important;
}
.header-nav-m .ant-select-selection-search input {
  height: 0 !important;
}
.header-nav-m-drop {
  width: 100% !important;
  height: fit-content !important;
  position: relative;
  top: 0 !important;
  border-radius: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  bottom: 0 !important;
  padding: 0;
}
.header-nav-m-drop .ant-select-item {
  text-align: center;
  text-transform: uppercase;
  padding: 10px 12px;
  font-weight: 400;
  font-size: 13px;
}
.header-nav-m-drop .ant-select-item p {
  color: #063a57;
}
.header-nav-m-drop .ant-select-item-option-selected {
  font-weight: 700;
}
.header-nav-m-drop .ant-select-item-option-active {
  background: none !important;
}
.header-nav-m-btn {
  font-size: 2.1875rem;
  color: #fff;
  position: absolute;
  right: 25px;
}
.intro {
  height: calc(100vh - 97px);
  min-height: 450px;
  background-image: url(../../../public//images/intro-bck.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -260px;
  position: relative;
  top: -1px;
}
.intro-bck {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
}
.intro-content {
  position: relative;
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.intro-content .loading-icon {
  position: absolute;
  bottom: -50%;
}
.intro-content-logo {
  max-width: 90%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: -10%;
}
.intro-content-logo img {
  transition: all 0.6s ease-out;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.intro-content-inner {
  text-align: left;
  color: #fff;
  width: 100%;
  position: absolute;
  bottom: 5%;
  padding: 0 5%;
  display: flex;
  flex-direction: column;
}
.intro-content-inner a {
  text-decoration: none;
  color: #fff;
}
.intro-content-inner p {
  font-weight: 400;
  font-size: 1.875rem;
  margin-bottom: 5px;
  letter-spacing: 0.5px;
}
.intro-content-inner small {
  font-weight: 400;
  font-size: 1.125rem;
  letter-spacing: 0.5px;
}
.intro-content-l {
  margin-bottom: 30px;
  text-align: center;
}
.intro-content-r {
  text-align: center;
}
.welcome {
  text-align: center;
  margin-top: 100px;
}
.welcome .section-heading h2 {
  opacity: 0;
  font-size: 2.8125rem;
}
.welcome .section-heading p {
  opacity: 0;
}
.menu {
  margin-top: 7.5rem;
}
.menu-animated .menu-nav li {
  opacity: 1 !important;
}
.menu .section-heading h2,
.menu .section-heading p {
  opacity: 0;
}
.menu-header {
  display: flex;
  justify-content: space-between;
}
.menu-nav-m-select {
  opacity: 0;
  width: 100%;
  text-align: center;
  background: transparent !important;
}
.menu-nav-m-select-focused {
  background: #fff !important;
}
.menu-nav-m-select.ant-select-open,
.menu-nav-m-select.ant-select-focused {
  opacity: 1;
}
.menu-nav-m-select .ant-select-selector {
  border: none !important;
  box-shadow: none !important;
  padding: 0 1px !important;
  height: 100% !important;
}
.menu-nav-m-select .ant-select-selector::after {
  content: "" !important;
}
.menu-nav-m-select .ant-select-selection-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-bottom: 2px solid #f8b46d;
  width: max-content;
  padding: 0 !important;
}
.menu-nav-m-select p {
  font-size: 1.75rem;
  line-height: 2.8125rem;
  letter-spacing: 0.5px;
  font-weight: 600;
  font-family: "Karma", serif;
  margin-bottom: -6px;
}
.menu-nav-m-option p {
  text-align: center;
  font-size: 1.125rem;
  color: dimgray;
  padding: 5px 0;
}
.menu-nav-m .ant-select-open p {
  color: #2a2a2a;
}
.menu-nav-m .ant-select-arrow {
  font-size: 1.375rem;
}
.menu-nav-m .ant-select-item-option-selected {
  background-color: white !important;
}
.menu-nav {
  display: none;
}
.menu-nav ul {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 50px;
  row-gap: 15px;
  list-style: none;
}
.menu-nav ul li {
  opacity: 0;
}
.menu-nav ul li p {
  font-weight: 500;
  font-size: 1.125rem;
  cursor: pointer;
  transition: all 0.25s ease-out;
  white-space: nowrap;
  animation-fill-mode: backwards !important;
}
.menu-nav ul li p:hover {
  transform: translateY(7px) scale(1.07);
}
.menu-nav ul li p.active {
  transform: translateY(7px) scale(1.07);
}
.menu-nav ul li .anticon-delete {
  margin-left: 5px;
  color: red;
  font-size: 17px;
}
.menu-nav-bck {
  margin-top: 70px;
  height: clamp(200px, 20vh, 400px);
  width: 100%;
  overflow: hidden;
}
.menu-nav-bck-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #eeeeee;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 60%;
  transition: transform 0.8s ease-out;
  transform: scale(1.3);
}
.menu-nav-bck-inner .anticon-upload {
  font-size: 70px;
  color: #295f95;
}
.menu-nav-bck-inner .anticon-upload:hover,
.menu-nav-bck-inner .anticon-upload:active,
.menu-nav-bck-inner .anticon-upload:focus {
  cursor: pointer;
}
.menu-nav-content {
  margin-top: 70px;
}
.menu-nav-content-header {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
}
.menu-nav-content-header .heading h2 {
  font-family: "Karma", serif;
  font-size: 1.75rem;
  font-weight: 700;
  letter-spacing: 0.5px;
}
.menu-nav-content-header .heading h4 {
  font-family: "Karma", serif;
  color: #888;
  letter-spacing: 0.3px;
  font-size: 1.125rem;
  margin-top: 10px;
}
.menu-nav-content-header .heading p {
  font-size: 1.0625rem;
  line-height: 23px;
}
.menu-nav-content-header .nav {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}
.menu-nav-content .daily-menu {
  margin: 20px 0 30px 0;
  gap: 15px;
  display: flex;
  flex-wrap: wrap;
}
.menu-nav-content .daily-menu-select-w {
  min-width: 130px;
}
.menu-nav-content .daily-menu-select {
  min-width: 130px;
}
.menu-nav-content .list {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: -25px;
  margin-right: -25px;
}
.menu-nav-content .list .list-item:first-child .list-item-inner {
  border-top: 0;
}
.menu-nav-content .list-item {
  opacity: 0;
  flex: 0 0 100%;
  padding: 0 25px 0px 25px;
  max-width: 100%;
  overflow: hidden;
}
.menu-nav-content .list-item-inner {
  border-top: 1px solid #d8d8d8;
  padding: 25px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
}
.menu-nav-content .list-item-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}
.menu-nav-content .list-item-header div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu-nav-content .list-item-image-container {
  height: 175px;
  width: 100%;
  background-color: #eeeeee;
  display: inline-block;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 5px;
}
.menu-nav-content .list-item-image-container .image {
  object-fit: cover;
  object-position: center;
  min-height: 100%;
}
.menu-nav-content .list-item-text {
  width: 100%;
}
.menu-nav-content .list-item-text-header {
  display: inline-flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  line-height: 1;
}
.menu-nav-content .list-item-text-header-heading {
  display: inline-block;
  font-size: 1.4375rem;
  font-family: "Karma", serif;
  font-weight: 700;
  margin-right: 20px;
}
.menu-nav-content .list-item-text-header-price {
  font-size: 1.25rem;
  white-space: nowrap;
}
.menu-nav-content .list-item-text-content-description {
  font-size: 1.125rem;
  padding: 10px 0 0 0;
}
.menu-nav-content .list-item-text-content-allergens {
  display: inline-block;
  width: max-content;
  font-size: 1.125rem;
  color: dimgray;
  padding: 10px 0 0 0;
}
.menu-nav-content .list-item-e {
  opacity: 0;
  flex: 0 0 50%;
  padding: 0 25px 0px 25px;
  max-width: 100%;
  overflow: hidden;
}
.menu-nav-content .list-item-e-inner {
  border-top: 1px solid #d8d8d8;
  padding: 25px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
}
.menu-nav-content .list-item-e-group {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  flex-wrap: nowrap;
}
.menu-nav-content .list-item-e-group .input-wrapper {
  flex: 0 0 calc(33.33% - 20px);
}
.menu-nav-content .list-item-e-group-full .input-wrapper {
  width: 100%;
  flex: 0 0 100%;
}
.menu-nav-content .list-item-e-group-full textarea {
  width: 100%;
}
.menu-nav-content .list-item-e-group-full .upload-input-wrapper {
  width: 100%;
}
.menu-add-modal {
  z-index: 10001;
}
.menu-add-modal .ant-modal-mask {
  z-index: 10000;
}
.menu-add-modal-inner {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.menu .heading h2 {
  opacity: 0;
}
.menu .heading h4 {
  opacity: 0;
}
.about {
  display: flex;
  margin-top: 180px;
  overflow: hidden;
}
.about-l {
  z-index: 10;
  flex: 0 0 30%;
  display: none;
  overflow: hidden;
}
.about-l-inner {
  width: 100%;
  height: 100%;
  background-image: url(../../../public//images/about-bck.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  transition: transform 0.8s ease-out;
  transform: scale(1.3);
}
.about-content {
  flex: 0 0 100%;
  color: #fff;
  z-index: 2;
  padding: 12% 5%;
  background-image: url(../../../public//images/about-bck.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.about-content .section-heading {
  border-left: 5px solid #f8b46d;
  padding-left: 10px;
}
.about-content .section-heading h2 {
  position: relative;
  top: 4px;
  color: #fff;
}
.about-content .mobile-bck {
  position: absolute;
  inset: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.4);
}
.about-content-inner {
  position: relative;
  z-index: 2;
  max-width: 800px;
  margin: 0 auto;
}
.about-content-inner p {
  opacity: 0;
  margin-bottom: 15px;
  font-size: 1.1875rem;
  line-height: 1.5rem;
}
.about-r {
  flex: 0 0 30%;
  overflow: hidden;
  display: none;
  z-index: 10;
}
.about-r-inner {
  width: 100%;
  height: 100%;
  background-image: url(../../../public//images/about-bck.jpg);
  background-repeat: no-repeat;
  background-position: 100% 50%;
  background-size: cover;
  transition: transform 0.8s ease-out;
  transform: scale(1.5);
}
.about .section-heading h2 {
  opacity: 0;
}
.order-list {
  display: flex;
  flex-direction: column;
  padding: 60px 50px 0px 50px;
  gap: 80px;
}
.order-list-item {
  opacity: 0;
  flex: 0 0 calc(33.33% - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.25s ease-out !important;
  position: relative;
  padding: 0 0 15px 0;
  max-height: 80px;
  width: auto;
}
.order-list-item:hover:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.order-list-item a {
  color: #2a2a2a;
  text-decoration: none;
  height: 100%;
  width: 100%;
}
.order-list-item-header {
  text-align: center;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.order-list-item-header svg {
  font-size: 2.5rem;
}
.order-list-item-header .foodpanda-icon {
  width: 100%;
  height: 100%;
  max-height: 90px;
}
.order-list-item-header .bolt-icon {
  width: 100%;
  height: 100%;
  max-height: 70px;
}
.order-list-item-header .phone small {
  display: inline-block;
  font-size: 1.5625rem;
}
.order-list-item-header .phone p {
  font-size: 2.8125rem;
  font-weight: 500;
  white-space: nowrap;
}
.order-list-item::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -6px;
  left: 0;
  background-color: #f8b46d;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
.order .section-heading h2 {
  opacity: 0;
}
.order .section-heading p {
  opacity: 0;
}
.faq {
  text-align: center;
}
.faq-content {
  opacity: 0;
}
.faq-list {
  opacity: 1;
  max-width: 100%;
  margin: 0 auto;
  text-align: left;
  border: 0 !important;
  background-color: transparent;
}
.faq-list .ant-collapse {
  border: 0 !important;
}
.faq-list .ant-collapse-item {
  border-color: #bcc6d0;
}
.faq-list .ant-collapse-content {
  border: 0;
}
.faq-list .ant-collapse-content-box {
  padding-left: 44px !important;
  padding-top: 0 !important;
}
.faq-list .ant-collapse-header {
  background: #fff;
  align-items: center !important;
  padding: 18px 20px !important;
  border-radius: 0 !important;
}
.faq-list .ant-collapse-header-text {
  font-family: "Karma", serif;
  font-size: 1.375rem;
  font-weight: 600;
  letter-spacing: 0.0125rem;
  display: inline-block;
  line-height: 25px;
  margin-bottom: -6px;
}
.faq-list .ant-collapse-expand-icon {
  align-items: flex-start !important;
}
.faq-list .ant-collapse-arrow svg {
  fill: #f8b46d;
  font-size: 1rem;
  position: relative;
  top: 2.5px;
}
.faq-list-item {
  opacity: 1;
}
.faq .section-heading h2 {
  opacity: 0;
}
.ref {
  width: 100%;
  height: clamp(300px, 25vh, 500px);
  background-color: #d9e5eb;
}
.ref-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  text-align: center;
  max-width: 1000px;
}
.ref-content h2 {
  font-size: 35px;
  line-height: 55px;
  font-family: "Karma", serif;
}
.ref-content p {
  margin-top: 20px;
  color: #69707b;
}
.gallery {
  text-align: left;
}
.gallery-swiper {
  margin-top: 40px;
}
.gallery-swiper .swiper-slide {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.gallery-swiper .swiper-slide img {
  max-width: 100%;
  transition: transform 0.4s ease-out !important;
}
.gallery-swiper .swiper-slide img:hover {
  transform: scale(1.05) !important;
}
.gallery-swiper .swiper-slide .bck {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
}
.gallery-swiper .swiper-pagination-bullet-active {
  background: #f8b46d !important;
}
.gallery .animate__animated {
  animation-fill-mode: none;
}
.gallery .section-heading h2 {
  opacity: 0;
}
.gallery .section-heading p {
  opacity: 0;
}
.contact-content {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 0px;
}
.contact-content-l {
  flex: 0 0 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-bottom: 70px;
  flex-direction: column;
}
.contact-content-l-c {
  opacity: 0;
  padding-top: 25px;
}
.contact-content-l-l {
  opacity: 0;
  position: relative;
  margin-top: 30px;
}
.contact-content-l-r {
  opacity: 0;
  position: relative;
}
.contact-content-l a {
  text-decoration: none;
  color: #2a2a2a;
}
.contact-content-l svg {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}
.contact-content-l p {
  font-weight: 400;
  font-size: 2.5rem;
  letter-spacing: 0.03125rem;
}
.contact-content-l span {
  font-weight: 400;
  font-size: 1.5625rem;
  letter-spacing: 0.03125rem;
}
.contact-content-l small {
  font-weight: 400;
  font-size: 1.75rem;
  margin-bottom: 5px;
}
.contact-content-r {
  opacity: 0;
  flex: 0 0 50%;
}
.contact-content-r iframe {
  width: 100%;
  height: 500px;
  border: 0;
}
.contact .section-heading h2 {
  opacity: 0;
}
.find-us {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.find-us h2 {
  opacity: 0;
}
.find-us-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.find-us-content-map {
  height: 400px;
  width: 100%;
  max-width: 800px;
  padding: 0 20px;
  opacity: 0;
  border: none;
}
.pre-footer {
  margin-top: 80px;
  padding: 20px 0 50px 0;
  background: #063a57;
  text-align: center;
  color: #fff;
}
.pre-footer-content {
  max-width: clamp(320px, 25vw, 500px);
}
.pre-footer-content img {
  opacity: 0;
}
.pre-footer-content-inner div {
  margin-bottom: 10px;
}
.pre-footer-content-inner p {
  font-size: 17px;
  line-height: 22px;
}
.pre-footer-content-inner a {
  text-decoration: none;
  color: #fff;
}
.pre-footer .separator {
  width: clamp(20px, 1.5vw, 50px);
  height: 2px;
  background: white;
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 40px;
  opacity: 0.5;
}
.pre-footer .made-by {
  opacity: 0;
  margin-top: 40px;
}
.pre-footer .made-by p {
  font-size: 1rem;
  letter-spacing: 0.03125rem;
  font-weight: 300;
}
.pre-footer .made-by svg {
  width: 35%;
  height: 35%;
  fill: #fff;
}
.footer {
  background-color: #052f47;
  padding: 17px 0;
  color: #fff;
  text-align: center;
}
.footer p {
  opacity: 0;
}
.loading-icon {
  width: 70px;
  height: 70px;
}
@media screen and (min-width: 576px) {
  .intro {
    background-position: -150px;
  }
  .intro-content-inner {
    flex-direction: row;
    justify-content: space-between;
  }
  .intro-content-inner p {
    font-size: 1.6875rem;
  }
  .intro-content-inner small {
    font-size: 1.1875rem;
  }
  .intro-content-l,
  .intro-content-r {
    text-align: left;
  }
  .intro-content-logo {
    max-width: 70%;
  }
  .menu-nav-content-header {
    flex-direction: row;
    align-items: center;
  }
  .menu-nav-content-header .nav {
    margin-top: 0;
  }
  .menu .list-item-inner {
    flex-direction: row;
    gap: 15px;
  }
  .menu .list-item-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
  }
  .menu .list-item-header div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .menu .list-item-image-container {
    height: 175px;
    width: 175px;
    background-color: #eeeeee;
    display: inline-block;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 5px;
  }
  .menu .list-item-image-container:hover,
  .menu .list-item-image-container:focus,
  .menu .list-item-image-container:active {
    cursor: pointer;
  }
  .menu .list-item-image-container .image {
    object-fit: cover;
    object-position: center;
    min-height: 100%;
  }
  .contact-content {
    gap: 0px;
  }
  .contact-content-l-c {
    padding: 50px 0;
  }
  .contact-content-l-l {
    position: absolute;
    left: 20px;
    bottom: 20px;
  }
  .contact-content-l-r {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
  .loading-icon {
    width: 80px;
    height: 80px;
  }
  .find-us-content-map {
    width: 80%;
    height: 600px;
  }
}
@media screen and (min-width: 768px) {
  .intro {
    background-position: 0px;
  }
  .intro-content-inner p {
    font-size: 1.875rem;
  }
  .intro-content-inner small {
    font-size: 1.25rem;
  }
  .intro-content-logo {
    max-width: 70%;
  }
  .about {
    /*
    &-l {
      display: flex;
      flex: 0 0 25%;
    }

    &-r {
      display: flex;
      flex: 0 0 25%;
    } */
  }
  .faq-list {
    max-width: 90%;
  }
  .pre-footer {
    margin-top: 180px;
  }
  .loading-icon {
    width: 90px;
    height: 90px;
  }
}
@media screen and (min-width: 992px) {
  .header-inner {
    justify-content: space-between;
  }
  .header-logo img {
    max-width: 250px;
  }
  .header-nav {
    display: flex;
  }
  .header-nav-m {
    display: none;
  }
  .header-nav-m-btn {
    display: none;
  }
  .intro {
    background-position: 0px;
    height: calc(100vh - 122px);
  }
  .intro-content-logo {
    max-width: 60%;
  }
  .menu-nav {
    display: block;
  }
  .menu-nav-m {
    display: none;
  }
  .menu-nav-bck {
    height: clamp(300px, 25vh, 400px);
  }
  .menu-nav-content .list-item-inner {
    gap: 20px;
  }
  .order-list {
    flex-direction: row;
  }
  .order-list .foodpanda-icon {
    max-height: 100%;
  }
  .order-list .bolt-icon {
    max-height: 100%;
  }
  .order-list .phone small {
    font-size: 1.125rem;
  }
  .order-list .phone p {
    font-size: 1.875rem;
  }
  .about {
    /*&-content {
      flex: 0 0 40%;
    }
    &-l {
      flex: 0 0 30%;
    }

    &-r {
      flex: 0 0 30%;
    } */
  }
  .faq-list {
    max-width: 80%;
  }
  .gallery .section-content {
    padding: 0 25px;
  }
  .contact-content {
    flex-direction: row;
    gap: 0px;
  }
  .loading-icon {
    width: 100px;
    height: 100px;
  }
}
@media screen and (min-width: 1200px) {
  .intro-content-logo {
    max-width: 45%;
  }
  .menu-nav-content .list {
    flex-direction: row;
  }
  .menu-nav-content .list .list-item:nth-child(2) .list-item-inner {
    border-top: 0;
  }
  .menu-nav-content .list-item {
    flex: 0 0 50% !important;
  }
  .menu-nav-content .list-item-inner {
    padding: 20px 15px;
  }
  .menu-nav-content .list-item:nth-child(2n) {
    border-left: 1px solid #d8d8d8;
  }
  .menu-nav-content .list-item-heading {
    width: fit-content;
  }
  .faq-list {
    max-width: 70%;
  }
  .intro-content-logo {
    max-width: 45%;
  }
  .menu-nav-content .list {
    flex-direction: row;
  }
  .menu-nav-content .list .list-item:nth-child(2) .list-item-inner {
    border-top: 0;
  }
  .menu-nav-content .list-item {
    flex: 0 0 50% !important;
  }
  .menu-nav-content .list-item-inner {
    padding: 20px 15px;
  }
  .menu-nav-content .list-item:nth-child(2n) {
    border-left: 1px solid #d8d8d8;
  }
  .menu-nav-content .list-item-heading {
    width: fit-content;
  }
  .menu-nav-content .list-item-e:first-child .list-item-e-inner {
    border-top: 0;
  }
  .menu-nav-content .list-item-e:nth-child(2n) {
    border-left: 1px solid #d8d8d8;
  }
  .menu-nav-content .list-item-e:nth-child(2n) .list-item-e-inner {
    border-top: 0;
  }
  .faq-list {
    max-width: 70%;
  }
}
@media screen and (min-width: 1600px) {
  .intro-content-logo {
    max-width: 35%;
  }
  .about-r-inner {
    background-image: url(../../../public//images/about-bck-2.jpg);
    background-position: 100% 100%;
  }
  .faq-list {
    max-width: 60%;
  }
  .contact-content {
    gap: 30px;
  }
}
@media screen and (min-width: 1800px) {
  .about-r-inner {
    background-image: url(../../../public//images/about-bck-2.jpg);
    background-position: 100% 100%;
  }
}
